@media (min-width: 1980px) {
    body{
        --font-size: 16px;
        --font-14: var(--font-size);
        font-size: var(--font-size)!important;
    }
    .common_icon{--height: 22px!important;--width: 22px!important;}
    .common_icon.sm, .common_icon_image.sm-x{--height: 20px!important;--width: 20px!important;}
    .input-form-group input{line-height: 1.8;font-size: var(--font-size);}
    .input-form-group .text-label{font-size: var(--font-size);}
}

@media (min-width: 2450px) {
    body{
        --font-size: 16px;
        --font-14: var(--font-size);
        font-size: var(--font-size)!important;
    }
    .common_icon{--height: 22px!important;--width: 22px!important;}
    .common_icon.sm, .common_icon_image.sm-x{--height: 20px!important;--width: 20px!important;}
    .input-form-group input{line-height: 1.8;font-size: var(--font-size);}
    .input-form-group .text-label{font-size: var(--font-size);}
}